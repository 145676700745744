import { isTouchDevice } from "common/utils/browser";
import { on as onEvent } from "common/utils/ui/event";
import "./component.scss";

export const urlEnum = {
  print_compositions: "print_compositions"
};

const tooltipContent = `
  <div
    class="softness-score__tooltip-content"
    id="js-softness-score-tooltip-content"
  >
    <div class="softness-score__tooltip-head">
      <div class="softness-score__tooltip-icon"></div>
      <h5 class="softness-score__tooltip-title">
        <b>CozyFeel™</b>, verified by Jiffy
      </h5>
      <a
        class="softness-score__tooltip-close js-softness-score-tooltip-close"
        role="button"
        tabindex="-1"
      ></a>
    </div>
    <div class="softness-score__tooltip-body">
      <ol class="softness-score__tooltip-list">
        <li class="softness-score__tooltip-list-item">
          <b class="softness-score__tooltip-list-taxon">Rough:</b>
          Rugged and textured.
        </li>
        <li class="softness-score__tooltip-list-item">
          <b class="softness-score__tooltip-list-taxon">Coarse:</b>
          Noticeable texture.
        </li>
        <li class="softness-score__tooltip-list-item">
          <b class="softness-score__tooltip-list-taxon">Everyday:</b>
          Soft and comfortable.
        </li>
        <li class="softness-score__tooltip-list-item">
          <b class="softness-score__tooltip-list-taxon">Smooth:</b>
          Incredible softness.
        </li>
        <li class="softness-score__tooltip-list-item">
          <b class="softness-score__tooltip-list-taxon">Luxe:</b>
          Luxurious and velvety.
        </li>
      </ol>

      <div class="softness-score__learn-more-wrap">
        <a class="softness-score__learn-more" href="../help#cozyfeel" ${
          window.MOBILE_APP ? "" : 'target="_blank"'
        }>
          Learn More
        </a>
      </div>
    </div>
  </div>`;
const tooltipTemplate = `
  <div class="tooltip softness-score__tooltip" role="tooltip">
    <div class="softness-score__tooltip-arrow tooltip-arrow"></div>
    <div class="softness-score__tooltip-inner tooltip-inner"></div>
  </div>`;

const $getTooltips = () => document.querySelectorAll(".js-softness-score-tooltip");
const $$getTooltips = () => $(".js-softness-score-tooltip");

/*  Bootstrap tooltips to stay open on hover https://stackoverflow.com/a/36056707 */
const initStayOpenOnHover = () => {
  const originalLeave = $.fn.tooltip.Constructor.prototype.leave;

  $.fn.tooltip.Constructor.prototype.leave = function leave(event) {
    const { currentTarget } = event;
    const options = this.getDelegateOptions();
    const dynamicDataValue = $(currentTarget)[this.type](options).data(`bs.${this.type}`);
    const self = event instanceof this.constructor ? event : dynamicDataValue;

    originalLeave.call(this, event);

    if (currentTarget) {
      const $$container = $(`#${$(currentTarget).attr("aria-describedby")}`);
      const hasLink = !!$("a[href]", $$container).length;
      if (!hasLink) return;

      const { timeout } = self;
      $$container.one("mouseenter", () => {
        clearTimeout(timeout);

        $$container.one("mouseleave", () => {
          $.fn.tooltip.Constructor.prototype.leave.call(self, self);
        });
      });
    }
  };
};

const initTooltip = () => {
  const $$tooltips = $$getTooltips();

  $$tooltips
    .tooltip({
      animation: false,
      container: "body",
      delay: {
        show: isTouchDevice() ? 0 : 500,
        hide: 100
      },
      html: true,
      title: tooltipContent,
      template: tooltipTemplate,
      trigger: isTouchDevice() ? "click" : "hover"
    })
    .on("shown.bs.tooltip", function shownCallback() {
      $$tooltips.not(this).tooltip("hide");
    });
};

const initTooltipClose = () => {
  const callback = e => {
    e.preventDefault();
    $$getTooltips().tooltip("hide");
  };

  onEvent("click", ".js-softness-score-tooltip-close", callback, true);
};

const stopBubbling = () => {
  $getTooltips().forEach($badge => {
    $badge.addEventListener("click", e => {
      e.preventDefault();
      e.stopPropagation();
    });
  });
};

const init = () => {
  const currUrl = window.location.href;

  const isPrintCompositionsPage = currUrl.includes(urlEnum.print_compositions);

  initStayOpenOnHover();

  if (!isPrintCompositionsPage) {
    initTooltip();
  }

  initTooltipClose();
  stopBubbling();
};

export default init;
